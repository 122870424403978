import React, { useState, useEffect, createRef } from 'react';
import './styles/App.less';
import MenuBar from './components/menubar.js';
import Canvas from './components/canvas.js';
import Floats from './components/floats.js';
import HomePage from './components/homepage.js';
import Greeting from './components/greeting.js';
import Projects from './components/projects.js';
import SkillPage from './components/skillpage.js';
import Awards from './components/awards.js';
import Experience from './components/experience.js';
import Education from './components/education.js';
import Tips from './components/tips.js'
import Footnote from './components/footnote.js';
import { useTextArts } from './components/helpers/chomp.js'; 

function App() {
  const [pageNumber, setPageNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [greeting, setGreeting] = useState('');
  const refs = [createRef(), createRef(), createRef(), createRef(), createRef(), createRef(), createRef(), createRef()];
  
  useEffect(() => {
    const hours = new Date().getHours();
    setGreeting(<Greeting hours={hours} />);
  }, []);

  const handleScroll = () => {
    for (let i = 0; i < refs.length; i++) {
      if (refs[i].current) {
        const { top } = refs[i].current.getBoundingClientRect();
        if (top >= 0 && top <= window.innerHeight) {
          setActivePage(i + 1);
          break;
        }
      }
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useTextArts();

  const nextPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber < 10 ? prevPageNumber + 1 : 10);
  };

  const prevPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber > 1 ? prevPageNumber - 1 : 1);
  };


  const handleMenuClick = (pageIndex) => {
    if (refs[pageIndex] && refs[pageIndex].current) {
      const offset = 50;
      const elementPosition = refs[pageIndex].current.offsetTop;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };


  return (
    <div className="App">
      <Floats>
        +
      </Floats>
      <MenuBar onMenuClick={handleMenuClick} />
      <div className="content" onScroll={handleScroll}>
      {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
          <div
            id={index === 1 ? "projects-page" : ""}
            className={`page ${index % 2 === 0 ? 'grey-bg' : 'white-bg'}
            ${index === 6 ? 'last-page' : ''}
            ${index === 0 ? 'first-page' : ''}`}
            key={index}
            ref={refs[index]}
          >
            {index === 0 ? (
                <>
                    <HomePage greeting={greeting} onLearnMoreClick={() => handleMenuClick(1)} />
                    <Canvas />
                </>
            ) : index === 1 ? (
              <>
                <Projects pageNumber={pageNumber} setPageNumber={setPageNumber} />
                {pageNumber < 10 && (
                  <div className="page-turn" onClick={nextPage}></div>
                )}
                {pageNumber > 1 && (
                  <div className="page-turn-back" onClick={prevPage}></div>
                )}
              </>
            ) : index === 2 ? (
              <SkillPage />
            ) : index === 3 ? (
              <Awards />
            ) : index === 4 ? (
              <Experience />
            ) : index === 5 ? (
              <Education />
            ) : index === 6 ? (
              <Tips />
            ) : null}
          </div>

        ))}
      </div>
      <Footnote />
    </div>
  );
}

export default App;