import React, { useState, useEffect } from 'react';
import '../styles/modules/menubar.less';
// import icon from '../icons/A.png';
import Search from './helpers/search.js';


function MenuBar({ onMenuClick }) {
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [hasDropdownBeenToggled, setHasDropdownBeenToggled] = useState(false);

    useEffect(() => {
        function handleResize() {
            const isMobile = window.innerWidth <= 900;

            // Hide or show the menu items based on screen width
            setIsMenuVisible(!isMobile);
            // Manage dropdown visibility
            const menuToggle = document.querySelector('.menu-toggle');
            if (isDropdownVisible) {
                menuToggle.classList.add('menu-toggle-visible');
            } else {
                menuToggle.classList.remove('menu-toggle-visible');
            }
        }

        function handleClickOutside(event) {
            if (isDropdownVisible && !event.target.closest('.dropdown-menu') && !event.target.closest('.menu-toggle')) {
                setIsDropdownVisible(false);
                document.querySelector('.menu-toggle').classList.remove('menu-toggle-visible');
            }
        }

        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    const toggleDropdown = () => {
        // If the dropdown is visible, close it
        if (isDropdownVisible) {
            setIsDropdownVisible(false);
            document.querySelector('.menu-toggle').classList.remove('menu-toggle-visible');
        } else {
            // If the dropdown is not visible, open it
            setIsDropdownVisible(true);
            setHasDropdownBeenToggled(true);
            document.querySelector('.menu-toggle').classList.add('menu-toggle-visible');
        }
    };

    const handleItemClick = (index) => {
        onMenuClick(index);
        if (isDropdownVisible) {
            setIsDropdownVisible(false);
            document.querySelector('.menu-toggle').classList.remove('menu-toggle-visible');
        }
    };

    return (
        <div className="menu-bar">
            <div className="menu-bar-start">
                {/* <img src={icon} alt="A icon" className="menu-a-icon" /> */}
            </div>
            {isMenuVisible && (
                <div className="menu-items">
                    <span onClick={() => handleItemClick(0)}>Home</span>
                    <span onClick={() => handleItemClick(1)}>Projects</span>
                    <span onClick={() => handleItemClick(2)}>Skills</span>
                    <span onClick={() => handleItemClick(3)}>Awards</span>
                    <span onClick={() => handleItemClick(4)}>Experience</span>
                    <span onClick={() => handleItemClick(5)}>Education</span>
                    <span onClick={() => handleItemClick(6)}>Tips</span>
                </div>
            )}
            <div className="right-section">
                <Search/>
                <div className="menu-toggle" onClick={toggleDropdown}>
                    <i className={`fas ${isDropdownVisible ? 'fa-times' : 'fa-bars'}`}></i>
                </div>
            </div>
            {hasDropdownBeenToggled && (
                <div className={`overlay ${isDropdownVisible ? 'overlay-visible' : ''}`} onClick={() => setIsDropdownVisible(false)}></div>
            )}
            {hasDropdownBeenToggled && (
                <div className={`dropdown-menu ${isDropdownVisible ? 'slide-in' : 'slide-out'}`}>
                    <span className="dropdown-item" onClick={() => handleItemClick(0)}>Home</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(1)}>Projects</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(2)}>Skills</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(3)}>Awards</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(4)}>Experience</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(5)}>Education</span>
                    <span className="dropdown-item" onClick={() => handleItemClick(6)}>Tips</span>
                </div>
            )}
        </div>
    );
}

export default MenuBar;