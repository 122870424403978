import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const BarChart = ({ data }) => {
    const d3Container = useRef(null);

    // Function to get initial size based on window width
    const getInitialSize = () => {
        if (window.innerWidth > 1060) {
            return 200;
        } else if (window.innerWidth > 980) {
            return 175;
        } else if (window.innerWidth > 710) {
            return 150;
        } else if (window.innerWidth > 510) {
            return 125;
        } else if (window.innerWidth > 380) {
            return 100;
        } else {
            return 90;
        }
    };

    const [chartSize, setChartSize] = useState(getInitialSize()); // Initialize state based on window width

    useEffect(() => {
        const handleResize = () => setChartSize(getInitialSize());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (data && d3Container.current) {
            const svg = d3.select(d3Container.current);

            // Clear the SVG
            svg.selectAll("*").remove();

            // Dimensions and margins
            const margin = { top: 5, right: 5, bottom: 0, left: 3 };
            const width = chartSize - margin.left - margin.right;
            const height = chartSize - margin.top - margin.bottom;

            // X scale
            const x = d3.scaleBand()
                .domain(d3.range(data.length))
                .range([0, width])
                .padding(0.2);

            // Y scale
            const maxY = d3.max(data);
            const y = d3.scaleLinear()
                .domain([0, maxY * 1.28]) // max height of bars
                .range([height, 0]);

            // Append the x-axis
            svg.append("g")
                .attr("transform", `translate(${margin.left},${height + margin.top})`)
                .call(d3.axisBottom(x).tickFormat(() => '').tickSize(0))
                .select(".domain")
                .attr("stroke", "none")
                .attr("stroke-width", "5px");

            // Append the y-axis
            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .call(d3.axisLeft(y).ticks(0).tickSize(0))
                .select(".domain")
                .attr("stroke", "none")
                .attr("stroke-width", "3px");

            // Create bars
            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .attr("fill", "#FFCC01")
                .selectAll("rect")
                .data(data)
                .join("rect")
                .attr("x", (d, i) => x(i))
                .attr("y", d => y(d) - 6) // Adjusting the y position up, 3 with line
                .attr("height", d => height - y(d) + 6) // Adjusting the height
                .attr("width", x.bandwidth());
        }
    }, [data, chartSize]);

    return (
        <svg
            className="d3-component"
            width={chartSize}
            height={chartSize}
            ref={d3Container}
        />
    );
};

export default BarChart;
