import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { geoOrthographic, geoPath } from 'd3-geo';
import { feature } from 'topojson-client';
import worldData from '../helpers/land-countries.json';

const Globe = () => {
    const ref = useRef();

    const getInitialSize = () => {
        if (window.innerWidth > 1060) {
            return { width: 200, height: 200 };
        } else if (window.innerWidth > 980) {
            return { width: 175, height: 175 };
        } else if (window.innerWidth > 710) {
            return { width: 150, height: 150 };
        } else if (window.innerWidth > 510) {
            return { width: 125, height: 125 };
        } else if (window.innerWidth > 430) {
            return { width: 100, height: 100 };
        } else if (window.innerWidth > 380) {
            return { width: 100, height: 100 };
        } else {
            return { width: 90, height: 90 };
        }
    };

    const [globeSize, setGlobeSize] = React.useState(getInitialSize());

    useEffect(() => {
        const handleResize = () => {
            setGlobeSize(getInitialSize());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const projection = geoOrthographic()
            .scale((globeSize.width / 2))
            .translate([globeSize.width / 2, globeSize.height / 2])
            .rotate([50, -30]);

        const path = geoPath().projection(projection);

        const svg = d3.select(ref.current)
            .attr('width', globeSize.width)
            .attr('height', globeSize.height);

        svg.selectAll("*").remove();

        svg.append('path')
            .datum({ type: 'Sphere' })
            .attr('d', path)
            .attr('fill', '#0A84FF');

        svg.append('path')
            .datum(feature(worldData, worldData.objects.land))
            .attr('d', path)
            .attr('class', 'land')
            .attr('fill', 'white');

        svg.append('path')
            .datum(feature(worldData, worldData.objects.countries))
            .attr('d', path)
            .attr('class', 'countries')
            .attr('fill', 'none')
            .attr('stroke', '#161618')
            .attr('stroke-width', 1.25);

    }, [globeSize]);

    return (
        <svg ref={ref} width={globeSize.width} height={globeSize.height}></svg>
    );
};

export default Globe;