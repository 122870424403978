import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const WaffleChart = ({ data, darkSquares }) => {
    const d3Container = useRef(null);
    const getInitialSize = () => {
        if (window.innerWidth > 1060) {
            return 200;
        } else if (window.innerWidth > 980) {
            return 175;
        } else if (window.innerWidth > 710) {
            return 150;
        } else if (window.innerWidth > 510) {
            return 125;
        } else if (window.innerWidth > 380) {
            return 100;
        } else {
            return 90;
        }
    };

    const [chartSize, setChartSize] = useState(getInitialSize());

    useEffect(() => {
        const handleResize = () => setChartSize(getInitialSize());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (d3Container.current) {
            const svg = d3.select(d3Container.current);

            // Dimensions and settings
            const squares = 100; // total number of squares
            const perRow = 10; // squares per row
            const squareSize = chartSize / perRow;

            // Clear previous content
            svg.selectAll("*").remove();

            // Create squares
            svg.selectAll("rect")
                .data(d3.range(squares))
                .enter()
                .append("rect")
                .attr("width", squareSize)
                .attr("height", squareSize)
                .attr("x", (d, i) => squareSize * (i % perRow))
                .attr("y", (d, i) => squareSize * Math.floor(i / perRow))
                .attr("fill", (d, i) => darkSquares.includes(i) ? "#D70015" : "#e4a5aa")
                .attr("stroke", "#161618")
                .attr("stroke-width", "3px");
        }
    }, [chartSize, data, darkSquares]);

    return (
        <svg
            className="d3-component"
            width={chartSize}
            height={chartSize}
            ref={d3Container}
        />
    );
};

export default WaffleChart;
