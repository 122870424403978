import React, { useState, useEffect } from 'react';
import '../styles/modules/skillpage.less';

const SkillPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    // Array of skills with names and associated FontAwesome icons
    const skills = [
        { name: 'React', icon: 'fab fa-react' },
        { name: 'JavaScript', icon: 'fab fa-js-square' },
        { name: 'D3.js', icon: 'fas fa-chart-line' },
        { name: 'MapBox', icon: 'fas fa-map-marked-alt' }, 
        { name: 'R', icon: 'fas fa-r' },
        { name: 'HTML', icon: 'fab fa-html5' },
        { name: 'CSS', icon: 'fab fa-css3-alt' },
        { name: 'Less', icon: 'fab fa-less' },
        { name: 'Node.js', icon: 'fab fa-node-js' },
        { name: 'Express.js', icon: 'fas fa-cubes' },
        { name: 'PostgreSQL', nameSmallScreen: 'Postgres', icon: 'fas fa-circle-nodes' },
        { name: 'Python', icon: 'fa-brands fa-python' },
        { name: 'Git', icon: 'fab fa-git-alt' },
        { name: 'Bash', icon: 'fas fa-terminal' },
        { name: 'SQL', icon: 'fas fa-database' },
        { name: 'QGIS', icon: 'fas fa-globe' },
        { name: 'Excel & Sheets', icon: 'fas fa-file-excel' },
        { name: 'Figma', icon: 'fab fa-figma' },
        { name: 'Datawrapper', nameSmallScreen: 'Data Wrapper', icon: 'fas fa-chart-pie' },
        { name: 'Flourish', icon: 'fas fa-seedling' },
        { name: 'Adobe Suite', icon: 'fas fa-photo-film' },

        { name: 'LangChain', icon: 'fas fa-link' },
    ];

       return (
            <div className="skills-container">
                <h1 id="skills-title">Skills</h1>
                <div className="grid-skill-page-wrapper">
                    {skills.slice(0, window.innerWidth <= 980 ? 22 : 21).map((skill, index) => (
                        <div key={index} className={`grid-cell cell-${index + 1}`}>
                            <div className="skill-icon"><i className={skill.icon}></i></div>
                            <div className="skill-text">
                                {windowWidth <= 395 ? skill.nameSmallScreen || skill.name : skill.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
}

export default SkillPage;
