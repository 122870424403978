import React from 'react';
import less from '../styles/modules/footnote.less';
import github from '../icons/github.svg';
// import twitter from '../icons/twitter.svg';
import linkedin from '../icons/linkedin.svg';

const currentYear = new Date().getFullYear();

const handleIconClick = (url) => {
    window.open(url, '_blank');
};

const Footnote = () => {
    return (
        <div className="footnote">
            <div className="footer-github-icon-container">
                <img src={github} alt="GitHub icon" className="github icon w-10" onClick={() => handleIconClick('https://github.com/alexandramharris')} />
                {/* <img src={twitter} alt="Twitter icon" className="twitter icon w-10" onClick={() => handleIconClick('https://twitter.com/harris_alex_m')} /> */}
                <img src={linkedin} alt="LinkedIn icon" className="linkedin icon w-10" onClick={() => handleIconClick('https://www.linkedin.com/in/alexandra-harris-7282ba192')} />
            </div>
            <p className="copyright">© {currentYear} Alexandra Harris</p>
        </div>
    );
}

export default Footnote;
