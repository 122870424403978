import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import '../styles/modules/education.less';
import bell from './helpers/bell.wav';
import crowd from './helpers/sports-crowd.wav';
import lake from './helpers/lake.wav';
import volume from '../icons/volume.svg';
import noVolume from '../icons/novolume.svg';



mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Education = () => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const observer = useRef(null);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [boxData, setBoxData] = useState(null);
    const [isVolumeOn, setIsVolumeOn] = useState(false);
    const bellSoundRef = useRef(new Audio(bell));
    const crowdSoundRef = useRef(new Audio(crowd));
    const lakeSoundRef = useRef(new Audio(lake));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);
    const currentStopRef = useRef(null);
    const [audioInitialized, setAudioInitialized] = useState(false);

    const primeAudio = () => {
        // Simply load the audio files without playing them
        if (!bellSoundRef.current) bellSoundRef.current = new Audio(bell);
        if (!crowdSoundRef.current) crowdSoundRef.current = new Audio(crowd);
        if (!lakeSoundRef.current) lakeSoundRef.current = new Audio(lake);
    
        // Set the audio to be loaded without playing
        bellSoundRef.current.load();
        crowdSoundRef.current.load();
        lakeSoundRef.current.load();
    
        setAudioInitialized(true);
    };
    

    const [currentStopIndex, setCurrentStopIndex] = useState(-1);

    const initializeAndPlayAudio = (audioFile, audioRef) => {
        if (!audioRef.current) {
            audioRef.current = new Audio(audioFile);
        }
        audioRef.current.volume = 0.3;
        audioRef.current.play().catch(e => console.error("Error playing audio: ", e));
    };
    
    const toggleVolume = () => {
        setIsVolumeOn(prevState => {
            const newState = !prevState;
    
            // If turning the volume on for the first time, initialize and prime the audio
            if (newState && !audioInitialized) {
                primeAudio();
                const currentStop = animationStops[currentStopIndex];
                if (currentStop && currentStop.audio) {
                    // Determine which audio to play based on the currentStop's audio ref
                    if (currentStop.audio === bellSoundRef) {
                        initializeAndPlayAudio(bell, bellSoundRef);
                    } else if (currentStop.audio === crowdSoundRef) {
                        initializeAndPlayAudio(crowd, crowdSoundRef);
                    } else if (currentStop.audio === lakeSoundRef) {
                        initializeAndPlayAudio(lake, lakeSoundRef);
                    }
                }
            } else {
                // If turning off, ensure all audio is stopped
                if (!newState) {
                    stopAudio(bellSoundRef);
                    stopAudio(crowdSoundRef);
                    stopAudio(lakeSoundRef);
                }
            }
    
            return newState;
        });
    };
    
    const playAudio = (audioRef) => {
        if (audioRef && audioRef.current && isVolumeOn) {
            audioRef.current.volume = 0.5; // Reduce the volume
            audioRef.current.play().catch(e => console.error("Error playing audio: ", e));
        }
    };

    const stopAudio = (audioRef) => {
        if (audioRef && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const animationStops = [
        {
            center: [-82.3475933499141, 29.648696578377276],
            zoom: 16,
            box: {
                coordinates: [-82.3491, 29.6499],
                text: "College of Journalism and Communications",
                width: 170,
                height: 50,
                left: 0,
                top: 0
            },
            
            speed: 0.6,
            pitch: 60,
            bearing: 0,
            waitDuration: 3
        },
        {
            center: [-82.343115, 29.649004263],
            zoom: 18,
            box: {
                coordinates: [-82.3414, 29.649545],
                text: "Century Bell Tower",
                width: 150,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 60,
            bearing: 70,
            waitDuration: 5,
            audio: bellSoundRef
        },
        {
            center: [-82.348732, 29.650004541],
            zoom: 16.5,
            box: {
                coordinates: [-82.3478, 29.6479],
                text: "Ben Hill Griffin Stadium",
                width: 190,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 30,
            bearing: 180,
            waitDuration: 5,
            audio: crowdSoundRef
        },
        {
            center: [-82.361298, 29.64327801],
            zoom: 16,
            box: {
                coordinates: [-82.3613, 29.6404],
                text: "Lake Alice",
                width: 100,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 30,
            bearing: 180,
            waitDuration: 5,
            audio: lakeSoundRef
        },

    ];

    const mobileAnimationStops = [
        {
            center: [-82.347, 29.6486965],
            zoom: 16,
            box: {
                coordinates: [-82.3491, 29.6499],
                text: "College of Journalism and Communications",
                width: 170,
                height: 50,
                left: 0,
                top: 0
            },
            
            speed: 0.6,
            pitch: 60,
            bearing: 0,
            waitDuration: 3
        },
        {
            center: [-82.34287273963835, 29.648788479159037],
            zoom: 17.5,
            box: {
                coordinates: [-82.3414, 29.65017],
                text: "Century Bell Tower",
                width: 150,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 60,
            bearing: 70,
            waitDuration: 5,
            audio: bellSoundRef
        },
        {
            center: [-82.3495530743375, 29.650007956646874],
            zoom: 15.9,
            box: {
                coordinates: [-82.3475, 29.6487],
                text: "Ben Hill Griffin Stadium",
                width: 190,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 30,
            bearing: 180,
            waitDuration: 5,
            audio: crowdSoundRef
        },
        {
            center: [-82.36293177054057, 29.643375858986573],
            zoom: 15.1,
            box: {
                coordinates: [-82.36033490560658, 29.641237170506372],
                text: "Lake Alice",
                width: 100,
                height: 30,
                left: 0,
                top: 0
            },
            speed: 0.4,
            pitch: 30,
            bearing: 180,
            waitDuration: 5,
            audio: lakeSoundRef
        },

    ];

    const convertCoordinatesToPixels = (coords) => {
        if (!map.current) return { x: 0, y: 0 };
        const pixels = map.current.project(coords);
        return { x: pixels.x, y: pixels.y };
    };

    // Function to add a box
    const addBox = (boxInfo) => {
        const pixelCoords = convertCoordinatesToPixels(boxInfo.coordinates);
        setBoxData({ ...boxInfo, left: pixelCoords.x, top: pixelCoords.y });
    };

    const removeBox = () => {
        setBoxData(null);
    };

    const handleAudioForCurrentStop = () => {
        const currentStop = animationStops[currentStopIndex];
        if (currentStop && currentStop.audio) {
            if (isVolumeOn) {
                playAudio(currentStop.audio);
            } else {
                stopAudio(currentStop.audio);
            }
        }
    };

    const animateMap = (stops, index = 0) => {
        if (index < stops.length) {
            const currentStop = stops[index];
            currentStopRef.current = currentStop;
            setCurrentStopIndex(index);

            map.current.flyTo({ ...currentStop, essential: true });

            map.current.once('moveend', () => {
                // Add box and handle audio
                addBox(currentStop.box);
                handleAudioForCurrentStop();

                setTimeout(() => {
                    removeBox();
                    stopAudio(currentStop.audio);
                    animateMap(stops, index + 1);
                }, currentStop.waitDuration * 1000);
            });
        } else {
            setAnimationCompleted(true);
            stopAudio(bellSoundRef);
            stopAudio(crowdSoundRef);
            stopAudio(lakeSoundRef);
        }
    };



    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/standard',
                center: [-82.34740271900503, 29.64826661450141],
                zoom: 0,
                pitch: 0,
                bearing: 0,
                antialias: true
            });

            map.current.scrollZoom.disable();
            map.current.dragPan.disable();
            map.current.on('styledata', () => {
                // Immediately re-disable after any style change
                map.current.scrollZoom.disable();
            });

            map.current.on('styledata', () => {
                if (!map.current.getSource('composite')) {
                    return;
                }
            
                const layers = map.current.getStyle().layers;
                let labelLayerId;
                for (let i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                        labelLayerId = layers[i].id;
                        break;
                    }
                }
                

                map.current.addLayer({
                    'id': '3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',
                        'fill-extrusion-height': [
                            'interpolate', ['linear'], ['zoom'],
                            15, 0,
                            15.05, ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate', ['linear'], ['zoom'],
                            15, 0,
                            15.05, ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 1
                    }
                }, labelLayerId);
                map.current.boxZoom.disable();
                map.current.scrollZoom.disable();
                map.current.dragPan.disable();
                map.current.dragRotate.disable();
                map.current.keyboard.disable();
                map.current.doubleClickZoom.disable();
                map.current.touchZoomRotate.disable();
            });
        }

        const animateMapIfNeeded = () => {
            if (!animationCompleted && mapContainer.current) {
                animateMap(isMobile ? mobileAnimationStops : animationStops);
            }
        };
    
        observer.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !animationCompleted) {
                    animateMapIfNeeded();
                    setAnimationCompleted(true);
                    if (observer.current) {
                        observer.current.disconnect();
                    }
                }
            });
        }, { threshold: 0.5 });
    
        if (mapContainer.current) {
            observer.current.observe(mapContainer.current);
        }
    
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [isMobile, animationCompleted]);

    useEffect(() => {
        handleAudioForCurrentStop();
    }, [isVolumeOn, currentStopIndex]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 950);
        };

        // Check screen size on initial load
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className="education-container">
            <h1 id="education-title">Education</h1>
            <div className="education-content">

                <div className="education-info">
                    {/* Left Column */}
                    <div className="info-column left-info">
                        <h2 className="education-header" id="university">University of Florida</h2>
                        <div id="university-info">
                            <ul>
                                <li id="degree">Bachelor of Science in Journalism</li>
                                <li id="date">April 2022</li>
                            </ul>
                        </div>
                        <h3 className="education-subheading" id="scholarship">Graduated with honors</h3>
                        <ul>
                            <li>Four-year scholarship award for academic achievement</li>
                        </ul>
                    </div>

                    {/* Right Column */}
                    <div className="info-column right-info">
                        <h3 className="education-subheading" id="specializations">Specializations</h3>

                        <ul>
                            <li>
                                <i className="fa-solid fa-chart-line specialization-icon"></i>
                                Data journalism
                            </li>
                            <li>
                                <i className="fa-solid fa-magnifying-glass specialization-icon"></i>
                                Investigative journalism
                            </li>
                            <li>
                                <i className="fa-solid fa-seedling specialization-icon"></i>
                                Environmental journalism
                            </li>
                            <li>
                                <i className="fa-solid fa-book-open specialization-icon"></i>
                                Magazine and feature writing
                            </li>
                        </ul>
                        <h3 className="education-subheading" id="concentration">Pre-Medical Concentration</h3>
                        <ul>
                            <li>Statistics, calculus, chemistry</li>
                        </ul>
                    </div>
                </div>




                <div ref={mapContainer} className="education-map">
                    {boxData && (
                        <div
                            className="boxData-style"
                            style={{
                                left: `${boxData.left}px`,
                                top: `${boxData.top}px`,
                                width: `${boxData.width}px`,
                                height: `${boxData.height}px`
                            }}
                        >
                            {boxData.text}
                        </div>
                    )}
                    <div className="circle-button" onClick={toggleVolume}>
                        {isVolumeOn ?
                            <img src={volume} alt="Volume on" /> :
                            <img src={noVolume} alt="No volume" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Education;