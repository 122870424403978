import React, { useEffect, useState } from 'react';
import resumePdf from './helpers/resume-2024.pdf';
import '../styles/modules/floats.less';

const handleContactClick = () => {
    window.location.href = "mailto:harrisalexmarie@gmail.com";
};

const handleItemClick = () => {
    fetch(resumePdf) // Fetch the PDF file
        .then(response => response.blob())
        .then(blob => {
            const fileURL = URL.createObjectURL(blob);
            
            // Download the PDF
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = 'harris-resume.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Open the PDF in a new tab
            window.open(fileURL, '_blank');
        })
        .catch(error => console.log("Error fetching PDF:", error));
};



const FloatingButton = () => {
    const [scrollDirection, setScrollDirection] = useState('up');

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY) {
                setScrollDirection('down');
            } else {
                setScrollDirection('up');
            }
            lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
        };

        window.addEventListener('scroll', updateScrollDirection);

        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
        };
    }, []);

    return (
        <>
            <button
                className="button-resume"
                onClick={() => handleItemClick()}
                style={{ opacity: scrollDirection === 'down' ? 0.5 : 1, backgroundColor: 'rgba(83, 168, 255, 0.99)' }}
            >
                <i className="fas fa-file-arrow-down icon"></i>
                <span className="float-text">Resume</span>
            </button>

            <button
                className="button-contact"
                onClick={handleContactClick}
                style={{ opacity: scrollDirection === 'down' ? 0.5 : 1, backgroundColor: 'rgba(83, 168, 255, 0.99)' }}
            >
                <i className="fas fa-envelope icon"></i>
                <span className="float-text">Contact</span>
            </button>
        </>
    );
};


export default FloatingButton;
