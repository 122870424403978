import { useEffect } from 'react';


export const useTextArts = () => {
    useEffect(() => {
      console.log(`
     _____          _____       _                 
    / ____|        / ____|     | |                
   | |  __  ___   | |  __  __ _| |_ ___  _ __ ___ 
   | | |_ |/ _ \\  | | |_ |/ _\` | __/ _ \\| '__/ __|
   | |__| | (_) | | |__| | (_| | || (_) | |  \\__ \\
    \\_____|\\___/   \\_____|\\__,_|\\__\\___/|_|  |___/
                                                
  `);
  
        console.log(`
  
                         ░░          ▒▒▒▒                                        
                        ▒▒▒▒▒▒▒▒▒▒  ▒▒▒▒▒▒▒▒▒▒                                        
                      ▒▒▒▒▓▓▓▓▓▓▒▒▒▒▒▒▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒                  ▓▓          
                    ▒▒▒▒██▓▓▓▓▒▒▒▒████▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒    ██▓▓████████        
                  ▒▒▒▒▓▓░░▓▓▓▓▓▓▓▓░░▓▓▓▓▓▓░░▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▓▒▒░░▒▒░░░░██▓▓██    
                ▒▒▒▒▒▒▒▒██████    ██▒▒████▓▓▒▒▒▒████████████▓▓▒▒▒▒██████▓▓██▒▒░░▒▒██  
            ▒▒▒▒▒▒▒▒▓▓░░██▓▓▓▓  ▓▓  ▓▓▓▓██▓▓▓▓▓▓░░░░▒▒░░▒▒░░░░░░▓▓██▓▓██████▓▓▓▓██▓▓  
        ▒▒▒▒▒▒▓▓▓▓▓▓░░▓▓▓▓██▓▓      ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓██▓▓▓▓▓▓▓▓██▓▓██▓▓▓▓▓▓▓▓██▓▓  
        ▒▒▒▒▓▓▓▓▓▓▓▓██▓▓████▓▓████▓▓▓▓██▓▓▓▓██▓▓██▓▓▓▓▓▓██▓▓▓▓▓▓▓▓▓▓▓▓          ░░    
      ▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓████▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓  ▓▓▓▓▓▓▓▓██▓▓▓▓▓▓          ░░    
    ▒▒▒▒▒▒▓▓▓▓████▓▓██████████████▓▓██████████████    ▓▓    ▓▓▓▓  ▓▓▓▓  ▓▓▓▓  ▒▒▒▒    
    ▒▒▒▒▓▓▓▓▓▓▓▓██▓▓▓▓▓▓▓▓▒▒    ▒▒▒▒▓▓▓▓▓▓▓▓██  ▒▒  ▒▒▓▓  ▓▓▓▓    ▓▓    ▓▓▓▓▒▒▒▒▒▒    
    ▒▒▒▒▓▓▓▓▓▓██████▓▓██▒▒▒▒▒▒  ▒▒    ▒▒  ▒▒    ▒▒▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒  
    ▒▒▒▒▓▓▓▓██▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒  ▒▒▒▒    ▒▒▒▒  ▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒  
    ▒▒▒▒▓▓██████████▓▓▒▒      ▒▒  ▒▒  ▒▒▒▒  ▒▒██████▒▒    ▒▒▒▒▒▒  ▒▒▒▒  ▓▓  ▒▒▒▒▒▒    
      ▒▒▒▒▓▓▓▓▓▓▓▓██▓▓▓▓▓▓██▓▓▓▓  ▒▒    ▒▒▓▓██▓▓▓▓▓▓▓▓██▓▓▒▒      ▒▒    ▓▓  ▓▓▒▒▒▒    
      ▒▒▒▒▒▒▓▓▓▓██████████████████▒▒    ▓▓████████████████████████████████████▓▓▓▓    
        ▒▒▒▒▒▒▓▓▓▓▓▓▓▓██▓▓██▓▓██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓        
          ▒▒▒▒▒▒▓▓██████▓▓██████▓▓▓▓██████▓▓████▓▓██████████████▓▓▓▓▓▓▓▓██▓▓          
            ▒▒▒▒▒▒▓▓▓▓▓▓▓▓██▓▓▓▓██▓▓██▓▓▓▓▓▓▓▓▓▓██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒              
              ▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒                
                    ▒▒▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒                    
                        ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒                          
                                  ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒                                  
                                        ▒▒▒▒▒▒                                        
     `);
        return () => { };
    }, []);
};