import React, { useState, useEffect } from 'react';
import '../../styles/modules/searchbar.less';

const Search = ({ placeholderText, onSearch }) => {
    const [toggleByIcon, setToggleByIcon] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayTerm, setDisplayTerm] = useState('');
    const [placeholder, setPlaceholder] = useState('Search keywords');
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const inputRef = React.useRef();

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        setDisplayTerm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const found = window.find(searchTerm);
            if (!found) {
                setPlaceholder("Not found, try again");
                setDisplayTerm(""); // Clear the display term
            } else {
                setPlaceholder("Search keywords");
            }
        }
    };

    const toggleSearchBar = () => {
        setToggleByIcon(true);
        setIsExpanded(!isExpanded);
        if (!isExpanded) {
            inputRef.current.focus();
        }
    };



    const handleFocus = () => {
        setIsExpanded(true);
        setPlaceholder("Search keywords");
        if (displayTerm === '') {
            setDisplayTerm('');
        }
        if (!isMenuVisible) {
        }
    };

    const handleBlur = () => {
        if (!toggleByIcon && !searchTerm) {
            setIsExpanded(false);
            setDisplayTerm('');
            setPlaceholder('');
        }
        setToggleByIcon(false);
    };


    useEffect(() => {
        const addGlobalSelectionStyle = () => {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `::selection { background: #FFDE17; } ::-moz-selection { background: #FFDE17; }`;
            style.id = 'globalSelectionStyle';
            document.head.appendChild(style);
        };

        const removeGlobalSelectionStyle = () => {
            const style = document.getElementById('globalSelectionStyle');
            if (style) {
                document.head.removeChild(style);
            }
        };

        // Event listeners
        const searchInput = document.querySelector('.search-bar');
        searchInput.addEventListener('focus', addGlobalSelectionStyle);
        searchInput.addEventListener('blur', removeGlobalSelectionStyle);

        // Cleanup
        return () => {
            searchInput.removeEventListener('focus', addGlobalSelectionStyle);
            searchInput.removeEventListener('blur', removeGlobalSelectionStyle);
            removeGlobalSelectionStyle();
        };
    }, []);


    return (
        <div className="search-container">
            <i className="fas fa-search search-icon" onClick={toggleSearchBar}></i>
            <input
                className={`search-bar ${isExpanded ? 'expanded' : ''}`}
                type="text"
                id="searchInput"
                name="searchInput"
                placeholder={placeholder}
                ref={inputRef}
                value={displayTerm}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
};

export default Search;