import React, { useState, useEffect, useRef } from 'react';
import '../styles/modules/projects.less';
import data from '../data/projects.json';
import imagePaths from './paths.js';
import { useSwipeable } from 'react-swipeable';

// Define the Projects component
const Projects = () => {
    // State variables for managing the active page, window width, and current data index
    const [activePage, setActivePage] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentDataStartIndex, setCurrentDataStartIndex] = useState(0);
    const [gridAnimation, setGridAnimation] = useState("");
    const previousActivePageRef = useRef(activePage);

    // Set up icon
    const Chevron = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="1em" width="1em">
            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
        </svg>
    `;

    // Function to determine the number of items per page based on window width
    const itemsPerPage = () => {
        if (windowWidth > 1300) return 9;
        if (windowWidth > 700) return 6;
        return 3;
    };

    // Calculate the total number of pages based on the data length and items per page
    const totalNumberOfPages = Math.ceil(data.length / itemsPerPage());

    // Handle page click event to update active page and current data start index
    const handlePageClick = (pageNum) => {
        setActivePage(pageNum);
        const newStartIndex = (pageNum - 1) * itemsPerPage();
        setUpcomingDataIndex(newStartIndex);
        setCurrentDataStartIndex(newStartIndex);
    };


    const [upcomingDataIndex, setUpcomingDataIndex] = useState(currentDataStartIndex);

    // Function to handle next click and update upcoming data index
    const handleNextClick = () => {
        setActivePage((prevPage) => {
            if (prevPage < totalNumberOfPages) {
                const nextPage = prevPage + 1;
                setUpcomingDataIndex((nextPage - 1) * itemsPerPage());
                return nextPage;
            }
            return prevPage;
        });
    };

    // Function to handle previous click and update upcoming data index
    const handlePreviousClick = () => {
        setActivePage((prevPage) => {
            if (prevPage > 1) {
                const prevPageNum = prevPage - 1;
                setUpcomingDataIndex((prevPageNum - 1) * itemsPerPage());
                return prevPageNum;
            }
            return prevPage;
        });
    };


    // Add a resize event listener to update window width on resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Function to render the inner container with dynamic styles
    const renderInnerContainer = (className, currentData) => {
        if (currentData) {
            const imagePath = imagePaths[currentData.Image] || 'white';

            const handleClick = () => {
                window.open(currentData.Link, '_blank');
            };

            // Add the clickable anchor tag on top of the div
            return (
                <div className={`inner-container`} onClick={handleClick}>
                    <a href={currentData.Link} target="_blank"></a>
                    <div id="project-image" style={{
                        position: 'absolute',
                        bottom: '0px',
                        top: '0px',
                        right: '-104%',
                        width: '100%',
                        borderRadius: '4px',
                        margin: '15px 0px',
                        backgroundImage: `url('${imagePath}')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        boxShadow: '0.75px 0.75px 0.75px 0px rgba(0,0,0,0.25)', // subtle drop shadow only on right and bottom
                    }}></div>
                    {className.includes('small') ? (
                        <>
                            <div className="section top-section">{currentData.Type}</div>
                            <div className="combined-bottom-section">{currentData.Headline}</div>
                        </>
                    ) : null}
                </div>
            );
        }
    };


    // Function to get the range of pages to display in pagination
    const getPageRange = () => {
        const maxButtons = 4;
        let startPage = Math.max(activePage - Math.floor(maxButtons / 2), 1);
        let endPage = startPage + maxButtons - 1;

        if (endPage > totalNumberOfPages) {
            endPage = totalNumberOfPages;
            startPage = Math.max(1, endPage - maxButtons + 1);
        }

        return Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i);
    };


    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => handleNextClick(),
        onSwipedRight: () => handlePreviousClick(),
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: true,
    });

    // Function to handle right chevron click
    const handleRightChevronClick = () => {
        if (activePage < totalNumberOfPages) {
            handleNextClick();
        }
    };

    // Function to handle left chevron click
    const handleLeftChevronClick = () => {
        if (activePage > 1) {
            handlePreviousClick();
        }
    };

    useEffect(() => {
        if (activePage > previousActivePageRef.current) {
            // Slide out to the left
            setGridAnimation("slide-out-to-left");

            // Delay the slide-in animation to allow the slide-out animation to complete
            setTimeout(() => {
                setGridAnimation("slide-in-from-right");
                setCurrentDataStartIndex(upcomingDataIndex);
            }, 500); // Use the same duration as the CSS animation
        } else if (activePage < previousActivePageRef.current) {
            // Slide out to the right
            setGridAnimation("slide-out-to-right");

            // Delay the slide-in animation to allow the slide-out animation to complete
            setTimeout(() => {
                setGridAnimation("slide-in-from-left");
                setCurrentDataStartIndex(upcomingDataIndex);
            }, 500); // Use the same duration as the CSS animation
        }

        // Update the previous active page ref after the animation decision
        previousActivePageRef.current = activePage;

        // Reset the animation class to prevent it from affecting subsequent re-renders
        const timer = setTimeout(() => {
            setGridAnimation("");
        }, 1000); // Duration matching the combined delay and CSS animations

        return () => clearTimeout(timer);
    }, [activePage, upcomingDataIndex]);

    // Define handleClick function
    const handleClick = (currentData) => {
        window.open(currentData.Link, '_blank');
    };



    return (
        <div id="projects-container">
            <h1 id="projects-title">Projects</h1>
            <div className="grid-page-wrapper">
                <div className="projects-page" {...handlers}>


                    {/* Add chevron icons for mobile scroll indication */}
                    {windowWidth <= 570 && (
                        <div
                            className={`chevron-icon left ${activePage === 1 ? 'grey-chevron' : ''}`}
                            dangerouslySetInnerHTML={{ __html: Chevron }}
                            onClick={handleLeftChevronClick}
                        />
                    )}


                    <div className={`grid-container ${gridAnimation}`}>

                        {data[currentDataStartIndex] && (
                            <div className="one bg-color small" onClick={() => handleClick(data[currentDataStartIndex])}>
                                {renderInnerContainer('one bg-color small', data[currentDataStartIndex])}
                            </div>
                        )}

                        {data[currentDataStartIndex + 1] && (
                            <div className="two bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 1])}>
                                {renderInnerContainer('two bg-color small', data[currentDataStartIndex + 1])}
                            </div>
                        )}

                        {data[currentDataStartIndex + 2] && (
                            <div className="three bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 2])}>
                                {renderInnerContainer('three bg-color small', data[currentDataStartIndex + 2])}
                            </div>
                        )}

                        {windowWidth > 700 && data[currentDataStartIndex + 3] && (
                            <div className="four bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 3])}>
                                {renderInnerContainer('four bg-color small', data[currentDataStartIndex + 3])}
                            </div>
                        )}

                        {windowWidth > 700 && data[currentDataStartIndex + 4] && (
                            <div className="five bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 4])}>
                                {renderInnerContainer("five bg-color small", data[currentDataStartIndex + 4])}
                            </div>
                        )}

                        {windowWidth > 700 && data[currentDataStartIndex + 5] && (
                            <div className="six bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 5])}>
                                {renderInnerContainer("six bg-color small", data[currentDataStartIndex + 5])}
                            </div>
                        )}

                        {windowWidth > 1300 && data[currentDataStartIndex + 6] && (
                            <div className="seven bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 6])}>
                                {renderInnerContainer('seven bg-color small', data[currentDataStartIndex + 6])}
                            </div>
                        )}

                        {windowWidth > 1300 && data[currentDataStartIndex + 7] && (
                            <div className="eight bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 7])}>
                                {renderInnerContainer('eight bg-color small', data[currentDataStartIndex + 7])}
                            </div>
                        )}

                        {windowWidth > 1300 && data[currentDataStartIndex + 8] && (
                            <div className="nine bg-color small" onClick={() => handleClick(data[currentDataStartIndex + 8])}>
                                {renderInnerContainer('nine bg-color small', data[currentDataStartIndex + 8])}
                            </div>
                        )}


                    </div>


                    {/* Add pagination container for screens over 700px */}
                    {windowWidth > 570 && (
                        <div id="pagination-container" className="desktop">
                            {activePage > 1 ? (
                                <div className="page-button double-width">
                                    <div className="icon-wrapper" onClick={handlePreviousClick}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="20px" width="20px" style={{ transform: "rotate(180deg)" }}>
                                            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                        </svg>
                                    </div>
                                </div>
                            ) : (
                                <div className="page-button double-width blank-button">
                                    <div className="icon-wrapper-grey">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="20px" width="20px" style={{ transform: "rotate(180deg)" }}>
                                            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {activePage < totalNumberOfPages ? (
                                <div className="page-button double-width">
                                    <div className="icon-wrapper" onClick={handleNextClick}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="20px" width="20px">
                                            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                        </svg>
                                    </div>
                                </div>
                            ) : (
                                <div className="page-button double-width blank-button">
                                    <div className="icon-wrapper-grey">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="20px" width="20px">
                                            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}



                    {/* Add pagination container for screens under 570px */}
                    {windowWidth <= 570 && (
                        <div id="pagination-container" className="mobile">
                            {getPageRange().map((pageNum) => (
                                <div
                                    key={pageNum}
                                    className={`mobile-page-circle ${pageNum === activePage ? 'active' : ''}`}
                                    onClick={() => handlePageClick(pageNum)}
                                >
                                </div>
                            ))}
                        </div>
                    )}


                    {/* Add chevron icons for mobile scroll indication */}
                    {windowWidth <= 570 && (
                        <div
                            className={`chevron-icon right ${activePage === totalNumberOfPages ? 'grey-chevron' : ''}`}
                            dangerouslySetInnerHTML={{ __html: Chevron }}
                            onClick={handleRightChevronClick}
                        />
                    )}


                </div>
            </div>
        </div>
    );

}

export default Projects