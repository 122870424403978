import React from 'react';
import TypeWriter from './typewriter.js';


const Greeting = ({ hours }) => {
    let greetingText;
    let greetingClass;
    if (hours >= 5 && hours <= 11) {
        greetingText = "Good morning, I'm ";
        greetingClass = "morning-greeting";
    } else if (hours > 11 && hours <= 16) {
        greetingText = "Good afternoon, I'm ";
        greetingClass = "afternoon-greeting";
    } else {
        greetingText = "Good evening, I'm ";
        greetingClass = "evening-greeting";
    }

    const name = "Alexandra";

    return (
        <div className={`greeting-outer-container ${greetingClass}`}>
            <div className="greeting-container">
                <TypeWriter message={greetingText} name={name} delay={100} />
            </div>
        </div>
    );
};

export default Greeting;








// import React from 'react';
// import TypeWriter from './typewriter.js';

// const Greeting = ({ hours }) => {
//     let greetingText;
//     let greetingClass;
//     if (hours >= 5 && hours <= 11) {
//         greetingText = "Good morning, I'm ";
//         greetingClass = "morning-greeting";
//     } else if (hours > 11 && hours <= 16) {
//         greetingText = "Good afternoon, I'm ";
//         greetingClass = "afternoon-greeting";
//     } else {
//         greetingText = "Good evening, I'm ";
//         greetingClass = "evening-greeting";
//     }

//     const name = "Alexandra";

//     return (
//         <div className={`greeting-outer-container ${greetingClass}`}>
//             <div className="greeting-container">
//                 <TypeWriter message={greetingText} name={name} delay={100} />
//             </div>
//         </div>
//     );
// };

// export default Greeting;
