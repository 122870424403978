import React, { useState, useEffect } from 'react';
import '../styles/modules/typewriter.less';

function TypeWriter({ message, delay, name }) {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < message.length + name.length + 1) {
            const timeoutId = setTimeout(() => {
                setCurrentIndex(currentIndex + 1);
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, message, name, delay]);

    const displayText = currentIndex < message.length
        ? message.slice(0, currentIndex)
        : message + name.slice(0, Math.max(0, currentIndex - message.length)) + (currentIndex > message.length + name.length ? "." : ""); 

    const renderedText = (
        <>
            {displayText.slice(0, message.length)}
            <span style={{ whiteSpace: 'nowrap' }}>
                <span style={{ color: currentIndex > message.length ? '#34C759' : 'inherit', fontWeight: 'bold' }}>
                    {displayText.slice(message.length, message.length + name.length)}
                </span>
                {displayText.slice(message.length + name.length)}
                <div className='cursorBlink'></div>
            </span>
        </>
    );

    return (
        <div>
            {renderedText}
        </div>
    );
}

export default TypeWriter;

