import React, { useState, useEffect, useRef } from 'react';
import '../styles/modules/awards.less';
import ONA from '../icons/ONA.png';
import SEJ from '../icons/SEJ.png';
import UF from '../icons/Scholarship.jpg';

const Awards = () => {
    const [visibleIndex, setVisibleIndex] = useState(-1);
    const [animationStarted, setAnimationStarted] = useState(false); // State to track if animation has started
    const awardsRef = useRef(null); // Ref for the awards container

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                // Check if the animation hasn't started and the awards section is entering the viewport
                if (!animationStarted && entries[0].isIntersecting) {
                    setAnimationStarted(true); // Prevent future animations
                    setVisibleIndex(0); // Start animations
                }
            },
            {
                threshold: 0.1 // Trigger when 10% of the awards section is visible
            }
        );

        if (awardsRef.current) {
            observer.observe(awardsRef.current);
        }

        return () => {
            if (awardsRef.current) {
                observer.unobserve(awardsRef.current);
            }
        };
    }, [animationStarted]);

    useEffect(() => {
        // Start revealing items one by one, with a delay between each, only if the section is in view and animation hasn't started
        if (animationStarted && visibleIndex >= 0 && visibleIndex < 2) {
            const timer = setTimeout(() => {
                setVisibleIndex(visibleIndex + 1);
            }, 400);
            return () => clearTimeout(timer);
        }
    }, [visibleIndex, animationStarted]);

    

    return (
        <div className="awards-container" ref={awardsRef}>
            <h1 id="awards-title">Awards</h1>
            <div className="award-grid-container">

            <div className={`award-grid-item ${visibleIndex >= 0 ? 'visible' : ''}`}>
                <div className="award-item-content">
                    <img src={ONA} alt="ONA" className="award-image" />
                    <div className="award-text-content">
                        <h2 className="award-headline">Online Journalism Awards</h2>
                        <p className="award-subhead">Student Team Winner 2023 — <span className="award-word">Watershed</span></p>
                        <div className="award-quote">
                        <p>"The enterprise journalism, the <span style={{fontWeight: 600}}>review of data</span> and the literal leg work were all synthesized and simplified into <span style={{fontWeight: 600}}>meaningful visuals</span> within an impactful story."</p>
                            <ul>
                                <li>"Alexandra Harris <span style={{fontWeight: 500}}>scraped</span> and cleaned up <span style={{fontWeight: 500}}>two decades of sewage spill data</span> to show how spills are worsening amid more-extreme rains and storms"</li>
                                <li>"How the spills and worsening rains are <span style={{fontWeight: 500}}>strongly correlated</span>"</li>
                                <li>"A series of <span style={{fontWeight: 500}}>interactives</span> allows Floridians to see the hotspots for urban sewage spills and the dirtiest waters"</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

                <div className={`award-grid-item ${visibleIndex >= 1 ? 'visible' : ''}`}>
                    
                    <img src={SEJ} alt="SEJ" className="award-image" />
                    <div className="award-text-content">
                    <h2 className="award-headline">Society of Environmental Journalists</h2>
                    <p className="award-subhead">Outstanding Student Reporting Third Place 2023 — <span className="award-word">Watershed</span></p>
                    <p className="award-quote">
                        "This series took an in-depth look at how the landmark federal legislation affected Florida.
                        <br></br><br></br>
                        It opens with an examination of the health of Tampa Bay both before and after the Clean Water Act's passage, and goes on to examine how the state is still <span style={{fontWeight: 500}}>failing to control sewer spills</span>, agricultural runoff, and other types of pollution."
                    </p>
                </div>
</div>

                <div className={`award-grid-item ${visibleIndex >= 2 ? 'visible' : ''}`}>
                    <img src={UF} alt="UF" className="award-image" />
                    <div className="award-text-content">
                    <h2 className="award-headline">University of Florida</h2>
                    <p className="award-subhead">Presidential Scholarship — <span className="award-word">2018</span></p>
                    <div className="award-quote">
                        <p>"Outstanding academic achievement"</p>
                        <ul>
                            <li>Four-year merit award</li>
                            <li>Grades and standardized test scores</li>
                            <li>Extracurricular involvement</li>
                            <li>Leadership</li>
                            <li>Community service</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        </div>
    );
    
}

export default Awards;
