import React, { Component } from 'react';
import { TweenLite, Circ } from 'gsap';

class Canvas extends Component {
    componentDidMount() {
        this.animateHeader = true; // Add this line
        this.initHeader();
        this.initAnimation();
        this.addListeners();
    }
    

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('scroll', this.scrollCheck);
        window.removeEventListener('resize', this.resize);
    }

    initHeader = () => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.target = { x: this.width / 2, y: this.height / 2 };

        this.largeHeader = document.getElementById('large-header');
        this.largeHeader.style.height = `${this.height}px`;

        this.canvas = document.getElementById('canvas');
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.ctx = this.canvas.getContext('2d');

        // create points
        this.points = [];
        for (let x = 0; x < this.width; x += this.width / 10) {
            for (let y = 0; y < this.height; y += this.height / 20) {
                const px = x + Math.random() * this.width / 10;
                const py = y + Math.random() * this.height / 20;
                const p = { x: px, originX: px, y: py, originY: py };
                this.points.push(p);
            }
        }

        // Initialize each point's closest points and circles
        this.points.forEach(point => {
            point.closest = this.points
                .map(p => ({ point: p, distance: this.getDistance(point, p) }))
                .filter(p => p.point !== point)
                .sort((a, b) => a.distance - b.distance)
                .slice(0, 5)
                .map(p => p.point);

            point.circle = new this.Circle(point, 2 + Math.random() * 2, 'rgba(92,83,234,0.3)');
        });
    };

    addListeners = () => {
        if (!('ontouchstart' in window)) {
            window.addEventListener('mousemove', this.mouseMove);
        }
        window.addEventListener('scroll', this.scrollCheck);
        window.addEventListener('resize', this.resize);
    };

    mouseMove = (e) => {
        let posx = 0;
        let posy = 0;
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        } else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        this.target.x = posx;
        this.target.y = posy;
    };

    scrollCheck = () => {
        if (document.body.scrollTop > this.height) this.animateHeader = false;
        else this.animateHeader = true;
    };

    resize = () => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.largeHeader.style.height = `${this.height}px`;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    };

    initAnimation = () => {
        this.animate();
        this.points.forEach(point => {
            this.shiftPoint(point);
        });
    };

    animate = () => {
        if (this.animateHeader) {
            this.ctx.clearRect(0, 0, this.width, this.height);
            this.points.forEach(point => {
                let distance = this.getDistance(this.target, point);
                point.active = 0;
                point.circle.active = 0;
                if (distance < 4000) {
                    point.active = 0.3;
                    point.circle.active = 0.6;
                } else if (distance < 20000) {
                    point.active = 0.1;
                    point.circle.active = 0.3;
                } else if (distance < 40000) {
                    point.active = 0.02;
                    point.circle.active = 0.1;
                }

                this.drawLines(point);
                point.circle.draw(this.ctx);
            });
        }
        requestAnimationFrame(this.animate);
    };

    shiftPoint = (p) => {
        TweenLite.to(p, 1 + 1 * Math.random(), {
            x: p.originX - 50 + Math.random() * 100,
            y: p.originY - 50 + Math.random() * 100,
            ease: Circ.easeInOut,
            onComplete: () => {
                this.shiftPoint(p);
            },
        });
    };

    drawLines = (p) => {
        if (!p.active) return;
        p.closest.forEach(point => {
            this.ctx.beginPath();
            this.ctx.moveTo(p.x, p.y);
            this.ctx.lineTo(point.x, point.y);
            this.ctx.strokeStyle = `rgba(52,199,89,${p.active})`;
            this.ctx.stroke();
        });
    };

    Circle = function(pos, rad, color) {
        this.pos = pos || null;
        this.radius = rad || null;
        this.color = color || null;
        this.active = false;

        this.draw = function(ctx) {
            if (!this.active) return;
            ctx.beginPath();
            ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
            ctx.fillStyle = `rgba(255,255,255,${this.active})`;
            ctx.fill();
        };
    };

    getDistance = (p1, p2) => {
        return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    };

    render() {
        return (
            <div id="large-header" className="large-header">
                <canvas id="canvas"></canvas>
            </div>
        );
    }
}

export default Canvas;
