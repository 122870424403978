import React, { useState, useEffect } from 'react';
import '../styles/modules/homepage.less';
import BarChart from './helpers/barchart.js';
import Waffle from './helpers/waffle.js';
import Globe from './helpers/globe.js';


const HomePage = ({ greeting, onLearnMoreClick }) => {

    // Bar chart
    const barChartData = [5, 10, 15, 20, 25, 20, 15, 10, 5];
   
    // Waffle chart
    function range(start, end) {
        return Array.from({ length: end - start + 1 }, (_, i) => i + start);
    }
    const darkSquares = [30,31,32,33,34].concat(range(40, 99));
  
    // Globe
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [globeSize, setGlobeSize] = useState(getGlobeSize(window.innerWidth));

    function getGlobeSize(width) {
        if (width > 1060) {
            return { width: 200, height: 200 };
        } else if (width > 980) {
            return { width: 175, height: 175 };
        } else if (width > 710) {
            return { width: 150, height: 150 };
        } else if (width > 510) {
            return { width: 125, height: 125 };
        } else if (width > 430) {
            return { width: 100, height: 100 };
        } else if (width > 380) {
            return { width: 100, height: 100 };
        } else {
            return { width: 90, height: 90 };
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
            setGlobeSize(getGlobeSize(newWidth));
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);



    

    return (
        <div className="first-page-content">
            <div className="triangle">
                <BarChart data={barChartData} />
            </div>
            <div className="square">
                <Waffle darkSquares={darkSquares} />
            </div>
            <div className="circle">
                <Globe width={globeSize.width} height={globeSize.height} />
            </div>

            <div className="greeting">{greeting}</div>

            <div className="learn-more" onClick={onLearnMoreClick}>
                <p id="learn-text">
                    Learn more
                </p>
            </div>

        </div>
    );
}

export default HomePage;

