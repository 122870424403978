import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.less';
import App from './App';

// Get the root DOM node where the React application will be mounted
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component into the root DOM node
// The App component is wrapped in React's StrictMode to highlight potential problems in the application during development
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

